<template>
  <div>
    <h5 class="my-3">Configurar Marca d'água</h5>

    <b-row :class="{ block: isMobile }">
      <b-col cols="12" md="12" class="mb-4">
        <b-form-group label="" label-for="global_affiliation">
          <b-form-checkbox
            v-model="water_mark"
            name="global_affiliation"
            size="lg"
            switch
          >
            <p class="info-checkbox">
              Marca d'água
              <span class="descricao-checkbox">
                Seus dados serão apresentados nos documentos do sistema.</span
              >
            </p>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col
        v-if="water_mark"
        cols="12"
        md="4"
        class="mr-3"
        :class="{ 'col-md-12': isMobile }"
      >
        <b-form-group label="" label-for="global_affiliation">
          <b-form-group label="" label-for="contract" data-anima="top">
            <b-form-file
              id="arquivo"
              name="arquivo"
              class="arquivo-input"
              plain
              accept=".pdf"
              multiple
              @change="changeFiles"
              v-validate="'required'"
            ></b-form-file>
            <!-- accept=".pdf,.zip,.rar,.7zip" -->
            <div class="drag-drop">
              <img src="@/assets/img/icons/files.svg" />
              <p>Arraste e solte o arquivo</p>
              <BaseButton variant="secondary_outline" class="escolher-file"
                >Escolher arquivo</BaseButton
              >
            </div>

            <ul>
              <li
                v-for="(file, index) in files"
                :key="file.name"
                class="arquivo-item"
                data-anima="top"
              >
                {{ file.name }}
                <div class="item-lixeira">
                  <img
                    src="@/assets/img/icons/lixeira.svg"
                    class="icon-lixeira"
                    @click="removeFile(index)"
                  />
                </div>
              </li>
            </ul>

            <b-form-invalid-feedback :state="!errors.has('arquivo')">
              Por favor, insira o arquivo da entrega
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-invalid-feedback :state="!(files.length == 0 && submit)">
            Por favor, insira o documento se quiser realizar o download
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="water_mark" cols="12" md="3" :class="{ 'mt-5': isMobile }">
        <b-form-group label="Tamanho da Fonte" label-for="font_size">
          <b-form-input
            id="font_size"
            v-model="font_size"
            type="text"
            placeholder="Ex: 12"
            min="1"
            max="100"
            v-mask="'###'"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="12">
        <b-button
          type="button"
          v-if="water_mark"
          class="btn-green"
          @click="submitFont"
          >Salvar e Baixar Documento</b-button
        >
        <b-button type="button" v-else class="btn-green" @click="submitFont"
          >Salvar</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserSettingsService from "@/services/resources/UserSettingsService";
import AttachmentsService from "@/services/resources/AttachmentsService";

const serviceSettings = UserSettingsService.build();
const serviceAttachments = AttachmentsService.build();

import axios from "axios";
import Cookies from "js-cookie";

export default {
  props: ["userID"],
  data() {
    return {
      water_mark: false,
      submit: false,
      global_affiliation: "",
      files: [],
      font_size: 14,
      client: {
        width: 0,
      },
    };
  },
  mounted() {
    this.fetchSettings();
  },
  computed: {
    isMobile() {
      return this.client.width <= 1140;
    },
  },
  methods: {
    submitFont() {
      let checkbox = "on";
      if (this.water_mark === false) {
        checkbox = "off";
      }
      let data = {
        type: "PROTECTED_FILE",
        protected_font_size: this.font_size,
        protected_file: checkbox,
      };
      serviceSettings
        .create(data)
        .then((response) => {
          if (response.success) {
            // this.submitDoc();
            this.createPDF();
            this.$bvToast.toast("Dados salvos com sucesso!", {
              title: "Marca d'água",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    createPDF() {
      if (this.files.length === 0) {
        this.submit = true;
        return;
      }
      let data = {
        file: this.files,
      };

      data = this.jsonToFormData(data);
      axios({
        url: process.env.VUE_APP_API_HOST + `/api/attachment/preview`,
        method: "POST",
        responseType: "blob",
        data: data,
        headers: {
          Authorization: Cookies.get("access_token"),
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Arquivo de Teste");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changeFiles(e) {
      this.files = [];
      this.files = [...this.files, ...e.target.files];
      this.files = this.files.reverse();
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    fetchSettings() {
      let data = {
        user_id: this.userID,
      };
      serviceSettings
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            if (element.key == "PROTECTED_FONT_SIZE") {
              this.font_size = element.value;
            } else if (element.key == "PROTECTED_FILE") {
              let checkbox = false;
              if (element.value === "on") {
                checkbox = true;
              }
              this.water_mark = checkbox;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.block {
  display: block !important;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: #002363 !important;
}
.container-top {
  padding-top: 50px !important;
}
@media screen and (max-width: 768px) {
  .container-item {
    padding: 30px !important;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 16px;
  }
}
input.arquivo-input {
  height: 35vh !important;
  z-index: 9999;
  opacity: 0;
  cursor: pointer;
}

.drag-drop {
  width: 100%;
  border-radius: 5px;
  border: 2px dashed rgba(33, 51, 210, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.drag-drop .escolher-file {
  background: rgba(33, 51, 210, 0.2);
  color: #2a63ab;
  border: none;
  padding: 0 25px !important;
  height: 40px;
  font-size: 13px;
  margin-top: 20px;
}
.lista-entrega ul li .icon-lixeira {
  width: 14px;
  filter: invert(50%);
}
.icon-lixeira:hover {
  filter: invert(0) !important;
}
.arquivo-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background: #f7f7f7;
  border-radius: 5px;
  font-size: 15px;
}
.arquivo-item + .arquivo-item {
  margin-top: 10px;
}
.arquivo-item .item-lixeira {
  width: 20px;
}
.arquivo-item img {
  cursor: pointer;
}
</style>

<template>
  <div class="container pt-4">
    <BaseHeader title="Configurações" class="mb-5"></BaseHeader>

    <b-tabs
      class="mt-4 container"
      nav-class="TabHeader no-border"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
      @activate-tab="tabSelect"
    >
      <b-tab title="Geral" active>
        <b-container fluid class="mt-1 p-5 wrapper container-item">
          <h5 class="my-3">Geral</h5>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="" label-for="global_affiliation">
                <b-form-checkbox
                  v-model="global_affiliation"
                  name="global_affiliation"
                  size="lg"
                  switch
                >
                  <p class="info-checkbox">
                    Afiliação Global
                    <span class="descricao-checkbox">
                      Afiliados recebe um valor pela venda, independente de qual
                      produto for adquirido pelo comprador.</span
                    >
                  </p>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <div v-if="!loading">
            <h5 class="my-4">Endereços</h5>
            <b-row class="mb-5">
              <b-col cols="12" md="6" v-if="endereco_principal">
                <div @click="openAddress" id="endereco">
                  <ItemAddress
                    :endereco="endereco_principal"
                    :principal="true"
                    :actions="false"
                  />
                </div>
                <b-tooltip
                  target="endereco"
                  title="Clique para gerenciar os endereços"
                />
              </b-col>
              <b-col cols="12" md="6" v-if="!endereco_principal">
                <div @click="openAddress" id="endereco">
                  <BaseButton variant="info3">Adicinar endereço</BaseButton>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-row>
            <b-col cols="12">
              <b-button type="button" class="btn-green" @click="generalSubmit"
                >Salvar</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
      <b-tab title="Integrações e Tokens">
        <b-container fluid class="mt-1 p-5 wrapper container-item">
          <h5 class="my-3">Chaves de acesso</h5>
          <b-row v-if="!loading">
            <b-col cols="12" md="6">
              <b-form-group label="Public Key" label-for="public_key">
                <b-form-input
                  id="public_key"
                  v-model="public_key"
                  type="text"
                  readonly
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="API Key" label-for="api_key">
                <b-form-input
                  id="api_key"
                  v-model="api_key"
                  type="text"
                  readonly
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="12">
              <BaseButton variant="secondary_outline" @click="onSubmit"
                >Gerar novas chaves de acesso</BaseButton
              >
            </b-col>
          </b-row>

          <div class="mt-5">
            <h5 class="my-3 webhook">Webhook</h5>

            <b-row v-if="!loading">
              <b-col cols="12" md="6">
                <b-form-group label="Webhook Token" label-for="webhook_token">
                  <b-form-input
                    id="webhook_token"
                    v-model="webhook_token"
                    type="text"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <BaseButton
                  variant="secondary_outline"
                  @click="newTokenWebhook"
                  :disabled="loading"
                  >Gerar novo token</BaseButton
                >
              </b-col>
            </b-row>
          </div>
        </b-container>
        <b-col class="mt-3 container-pixels" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </b-col>
      </b-tab>

      <!-- Assinaturas -->
      <b-tab title="Assinaturas">
        <b-container class="mt-1 p-5 wrapper container-item" v-if="!loading">
          <h5 class="mb-4">Configurar Assinaturas</h5>

          <b-form-group
            label="Deseja encerrar automaticamente assinaturas não pagas?"
          >
            <b-form-checkbox
              v-model="end_subscription_auto"
              name="end_subscription_auto"
              size="lg"
              switch
            >
              <p class="info-checkbox">
                Encerrar automaticamente
                <span class="descricao-checkbox"
                  >Terá a opção de selecionar a quantidade de dias para o
                  cancelamento.</span
                >
              </p>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group
            v-if="end_subscription_auto"
            label="Selecione a quantidade de dias para o cancelamento"
            data-anima="top"
          >
            <b-form-input
              placeholder="2"
              class="w-25"
              id="end_subscription_days"
              name="end_subscription_auto_days"
              v-model="end_subscription_auto_days"
              min="0"
              type="number"
              v-validate="'required|numeric|min_value:0'"
            ></b-form-input>
            <b-form-invalid-feedback
              :state="!errors.has('end_subscription_auto_days')"
            >
              Mínimo 0
            </b-form-invalid-feedback>
          </b-form-group>

          <b-row data-anima="top">
            <b-col cols="12">
              <BaseButton
                variant="secondary"
                :disabled="errors.has('end_subscription_auto_days')"
                @click="saveDays"
                >Salvar</BaseButton
              >
            </b-col>
          </b-row>
        </b-container>
        <b-col class="mt-3 container-pixels" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </b-col>
      </b-tab>

      <b-tab title="Marca d'água">
        <b-container fluid class="mt-1 p-5 wrapper container-item mb-4">
          <WaterMark :userID="userID"></WaterMark>
        </b-container>
      </b-tab>

      <!-- Notificações -->
      <b-tab title="Notificações">
        <b-container class="mt-1 p-5 wrapper container-item">
          <h5 class="mb-4">Configurar Notificações</h5>

          <b-form-group label="Selecione o Formato das Notificações">
            <b-form-select
              v-model="notification"
              :options="notifications"
              v-validate="'required'"
              name="connection"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="" label-for="pix">
            <b-form-checkbox
              v-model="pix"
              name="pix"
              size="lg"
              switch
              class="checkbox-cursor"
            >
              <p class="info-checkbox">
                Pix
                <span class="descricao-checkbox"
                  >Receber notificação por pix gerado.</span
                >
              </p>
            </b-form-checkbox>
          </b-form-group>

          <b-form-group label="" label-for="boleto">
            <b-form-checkbox
              v-model="boleto"
              name="boleto"
              size="lg"
              switch
              class="checkbox-cursor"
            >
              <p class="info-checkbox">
                Boleto
                <span class="descricao-checkbox"
                  >Receber notificação por boleto gerado.</span
                >
              </p>
            </b-form-checkbox>
          </b-form-group>

          <b-row data-anima="top">
            <b-col cols="12">
              <BaseButton variant="secondary" @click="saveNotifications"
                >Salvar</BaseButton
              >
            </b-col>
          </b-row>
        </b-container>
      </b-tab>

      <b-tab title="Permissões">
        <b-container fluid class="mt-1 p-5 wrapper container-item mb-4">
          <SubUsers />
        </b-container>
      </b-tab>

      <!-- Integrações -->
      <!-- <b-tab title="Integrações">
        <b-container class="mt-1 p-5 wrapper container-item">
          <h5 class="mb-4">Qual a Plataforma que deseja Integrar ?</h5>

          <form data-vv-scope="connection">
            <b-form-group label="Plataforma">
              <b-form-select
                v-model="plataform"
                :options="plataforms"
                v-validate="'required'"
                name="connection"
              ></b-form-select>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('connection.connection') : null"
              >
                Por favor, selecione a plataforma para esta conexão.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Nome da conexão"
                  label-for="connection_name"
                >
                  <b-form-input
                    id="connection_name"
                    name="connection_name"
                    v-model="connection_name"
                    v-validate="'required'"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="
                      submit ? !errors.has('connection.connection_name') : null
                    "
                  >
                    Por favor, insira um nome para esta conexão.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="API Key" label-for="api_key">
                  <b-form-input
                    id="api_key"
                    name="api_key"
                    v-model="connection_api_key"
                    v-validate="'required'"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="submit ? !errors.has('connection.api_key') : null"
                  >
                    Por favor, insira a API Key.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </form>

          <b-row>
            <b-col cols="12">
              <BaseButton variant="primary" @click="createIntegration"
                >Criar Integração</BaseButton
              >
            </b-col>
          </b-row>
        </b-container>
      </b-tab> -->
    </b-tabs>
    <!--<CallEvaluation /> -->
    <ListagemEnderecos :enderecosResponse="enderecos" @close="getAddress" />
  </div>
</template>
<script>
import TokenService from "@/services/resources/TokenService";
import UserService from "@/services/resources/UserService";
import UserSettingsService from "@/services/resources/UserSettingsService";
import ProductService from "@/services/resources/ProductService";
import WebhookTokenService from "@/services/resources/WebhookTokenService";
import Vue from "vue";
import WaterMark from "@/components/Seller/Settings/WaterMark.vue";
import SubUsers from "@/components/Seller/Settings/SubUsers.vue";
import ItemAddress from "@/components/Seller/Address/Item";
import ListagemEnderecos from "@/components/Seller/Address/ListagemEnderecos";

const service = TokenService.build();
const serviceUser = UserService.build();
const serviceSettings = UserSettingsService.build();
const serviceProducts = ProductService.build();
const serviceWebhookToken = WebhookTokenService.build();

import AddressService from "@/services/resources/AddressService";
const serviceAddress = AddressService.build();

export default {
  data() {
    return {
      notification: "",
      notifications: [
        { value: "", text: "Venda Realizada #000: Nome Produto" },
        { value: "notification1", text: "Venda Realizada #000" },
      ],
      pix: true,
      boleto: true,
      loading: false,
      endereco_principal: null,
      enderecos: [],
      global_affiliation: false,
      public_key: "",
      api_key: "",
      email: "",
      name: "",
      subject: "",
      message: "",
      url: "",
      products: [],
      submit: false,
      end_subscription_auto: false,
      end_subscription_auto_days: 1,
      product_id: null,
      upsell_script: "",
      plataform: null,
      connection_name: null,
      connection_api_key: null,
      plataforms: [
        { value: null, text: "Selecione uma plataforma" },
        { value: "memberkit", text: "Memberkit" },
      ],
      webhook_token: null,
    };
  },
  components: {
    SubUsers,
    WaterMark,
    ItemAddress,
    ListagemEnderecos,
  },
  computed: {
    userID() {
      return this.$store.getters.getProfile.id;
    },
  },
  methods: {
    saveNotifications() {
      let data = {
        type: "NOTIFICATIONS",
        type_sale_realized_notification: this.notification,
        type_sale_waiting_pix_notification: this.pix ? "1" : "0",
        type_sale_waiting_boleto_notification: this.boleto ? "1" : "0",
      };
      serviceSettings
        .create(data)
        .then((response) => {
          this.$bvToast.toast("Tipo de Notificação salvo com sucesso!", {
            title: "Notificação",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    newTokenWebhook() {
      Vue.swal({
        title: `Gerar novos tokens`,
        text: "Desejar invalidar esses tokens e gerar novos agora ?",
        type: "danger",
        showCancelButton: true,
        confirmButtonText: `Gerar novos tokens`,
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-link-info mt-3 mb-3",
        cancelButtonClass: "button button-info2 mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          serviceWebhookToken
            .create()
            .then((response) => {
              this.webhook_token = response.value;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getWebhookToken() {
      serviceWebhookToken.search().then((response) => {
        this.webhook_token = response.value;
      });
    },
    openAddress() {
      this.$bvModal.show("modal-address");
    },
    getAddress() {
      this.loading = true;
      this.endereco_principal = null;

      serviceAddress
        .search()
        .then((response) => {
          this.enderecos = response;
          this.endereco_principal = [...response].find(
            (item) => item.main == "1"
          );

          if (!this.endereco_principal) {
            this.endereco_principal = response[0];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveDays() {
      let auto = "off";
      if (this.end_subscription_auto) {
        auto = "on";
      }
      let data = {
        type: "CANCEL_DELAYED_SUBSCRIPTIONS",
        automatically_cancel_subscriptions: auto,
        days_to_cancel_late_subscriptions: this.end_subscription_auto_days,
      };
      serviceSettings
        .create(data)
        .then((response) => {
          if (this.end_subscription_auto === false) {
            this.$bvToast.toast("Cancelamento automatico removido!", {
              title: "Assinatura",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
          this.$bvToast.toast("Cancelamento automatico salvo com sucesso!", {
            title: "Assinatura",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tabSelect(newTabIndex) {
      if (newTabIndex == 0) this.fetchCredentials();
      else if (newTabIndex == 1) this.fetchSettings();
      else if (newTabIndex == 2) this.fetchSettings();
      else if (newTabIndex == 3) this.fetchSettings();
      else if (newTabIndex == 4) this.fetchSettings();
      else if (newTabIndex == 5) this.fetchSettings();
    },
    copyValue(text, type) {
      // Copy link
      const el = document.getElementById(type);
      el.select();
      document.execCommand("copy");

      this.$bvToast.toast("A chave foi copiada com sucesso!", {
        variant: "success",
        title: "Copiado!",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    generalSubmit() {
      let data = {
        id: this.userID,
        global_affiliation: this.global_affiliation,
      };

      serviceUser
        .update(data)
        .then((response) => {
          this.$bvToast.toast("As informações foram salvas com sucesso!", {
            title: "E-mail",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchSettings() {
      this.loading = true;
      let data = {
        user_id: this.userID,
      };

      serviceSettings
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            if (element.key == "AUTOMATICALLY_CANCEL_SUBSCRIPTIONS") {
              this.end_subscription_auto = element.value == "on" ? true : false;
            }
            if (element.key == "DAYS_TO_CANCEL_LATE_SUBSCRIPTIONS") {
              this.end_subscription_auto_days = element.value;
            }

            if (element.key == "EMAIL_CLIENT_SUBSCRIPTION_CANCELED_FROM") {
              this.email = element.value;
            }
            if (element.key == "EMAIL_CLIENT_SUBSCRIPTION_CANCELED_NAME") {
              this.name = element.value;
            }
            if (element.key == "EMAIL_CLIENT_SUBSCRIPTION_CANCELED_SUBJECT") {
              this.subject = element.value;
            }
            if (element.key == "EMAIL_CLIENT_SUBSCRIPTION_CANCELED_MESSAGE") {
              this.message = element.value;
            }
            if (element.key == "TYPE_SALE_REALIZED_NOTIFICATION") {
              this.notification = element.value;
            }
            if (element.key == "AUTOMATICALLY_CANCEL_SUBSCRIPTIONS") {
              this.end_subscription_auto = element.value == "on" ? true : false;
            }
            if (element.key == "DAYS_TO_CANCEL_LATE_SUBSCRIPTIONS") {
              this.end_subscription_auto_days = element.value;
            }
            if (element.key == "TYPE_SALE_WAITING_PIX_NOTIFICATION") {
              this.pix = element.value === "1" ? true : false;
            }
            if (element.key == "TYPE_SALE_WAITING_BOLETO_NOTIFICATION") {
              this.boleto = element.value === "1" ? true : false;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.loading = true;
      //Alerta para altear a chave

      service
        .search({
          url: "check-integration",
        })
        .then((response) => {
          if (response.length > 0) {
            let integrations = "";
            response.forEach((item) => {
              integrations += item + ", ";
            });
            integrations = integrations.slice(0, -2);

            Vue.swal({
              title: `Gerando novas chaves`,
              text: `Existe integrações${
                integrations.length > 1 ? "ões" : "ão"
              } (${integrations}) vinculada a API KEY deseja continuar com a geração da nova chave?`,
              type: "danger",
              showCancelButton: true,
              confirmButtonText: `Gerar novas chaves`,
              cancelButtonText: "Cancelar",
              customClass: "sweet-container",
              confirmButtonClass: "button button-link-info mt-3 mb-3",
              cancelButtonClass: "button button-info2 mr-4 mt-3 mb-3",
              buttonsStyling: false,
              reverseButtons: true,
            }).then((result) => {
              if (result.isConfirmed) {
                this.generateKey();
                this.$bvToast.toast(
                  `Altere sua integraç${
                    integrations.length > 1 ? "ões" : "ão"
                  } (${integrations}) com a nova chave gerada do API Key!`,
                  {
                    title: "Integração",
                    variant: "info",
                    autoHideDelay: 8000,
                    appendToast: true,
                  }
                );
              }
            });
          } else {
            this.generateKey();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    generateKey() {
      service
        .create()
        .then((response) => {
          // Set new credentials
          this.public_key = response.public_key;
          this.api_key = response.api_key;
          this.$bvToast.toast("Suas credenciais foram geradas com sucesso!", {
            title: "Credenciais",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeUpsellScript() {
      if (this.product_id) {
        this.upsell_script = `<script src="https://greenn.nyc3.digitaloceanspaces.com/upsell/upsell.js" id="greennUpsell" data-product-greenn-id="${this.product_id}"><\/script>`;
      } else {
        this.upsell_script = "";
      }
    },

    copyUpsell() {
      const el = document.getElementById("upsell_code");
      el.select();
      document.execCommand("copy");

      this.$bvToast.toast("O código foi copiado com sucesso!", {
        variant: "success",
        title: "Copiado!",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    fetchProducts() {
      this.products = [{ value: null, text: "Selecione um produto" }];

      let data = {
        list: true,
      };

      serviceProducts
        .search(data)
        .then((response) => {
          response.forEach((element, index) => {
            this.products.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchCredentials() {
      this.loading = true;
      service
        .search()
        .then((response) => {
          // Set new credentials
          this.public_key = response.public_key;
          this.api_key = response.api_key;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.global_affiliation =
      this.$store.getters.getProfile.global_affiliation == 1;
    this.fetchCredentials();
    this.fetchProducts();
    this.getAddress();
    this.getWebhookToken();
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 1rem;
}
.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 16px;
  color: #0d0d0f;
}

.container-item {
  padding: 50px !important;
}
@media screen and (max-width: 768px) {
  .container-item {
    padding: 30px !important;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 16px;
  }
}
</style>

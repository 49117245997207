<template>
  <BaseModal
    name="modal-permission-group"
    size="lg"
    title="Novo Grupo de Permissões"
    @shown="openModal"
    @hidden="reset"
  >
    <b-row>
      <b-col cols="12">
        <b-form novalidate v-if="!loading">
          <b-row class="mb-3">
            <b-col cols="6">
              <b-form-group label="Nome do Grupo" label-for="date-in">
                <b-form-input
                  id="name"
                  name="name"
                  v-model="name"
                  type="text"
                  v-validate="'required'"
                  placeholder="Ex: Grupo de Vendas"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  Por favor, insira o nome do grupo.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label="Permissões" label-for="date-in">
                <div>
                  <div
                    class="grid-list-itens"
                    v-for="(permission, index) in permissions"
                    :key="permission.id"
                  >
                    <div>
                      <div class="d-flex itens">
                        <img
                          v-if="permission.type === 'store'"
                          src="@/assets/img/permissions/store.svg"
                          alt="img"
                        />
                        <img
                          v-else-if="permission.type === 'view'"
                          src="@/assets/img/permissions/view.svg"
                          alt="img"
                        />
                        <img
                          v-else-if="permission.type === 'delete'"
                          src="@/assets/img/permissions/delete.svg"
                          alt="img"
                        />
                        <img
                          v-else-if="permission.type === 'edit'"
                          src="@/assets/img/permissions/edit.svg"
                          alt="img"
                        />
                        <div>
                          <p>{{ permission.title }}</p>
                          <span>{{ permission.description }}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <b-form-checkbox
                        v-model="permission.check"
                        @change="changeCheck(permission, index)"
                        name="check-button"
                        switch
                        size="lg"
                        class="ml-switch"
                      ></b-form-checkbox>
                    </div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div
          v-if="loading"
          class="
            Table-body
            py-4
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="secondary_outline" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="secondary" :disabled="loading" @click="onSubmit">
        Salvar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import SubUsersPermissionsService from "@/services/resources/SubUsersPermissionsService";
const serviceSubUsersPermissions = SubUsersPermissionsService.build();
import SubUsersGroupService from "@/services/resources/SubUsersGroupService";
const serviceSubGroup = SubUsersGroupService.build();
// import SubUsersService from "@/services/resources/SubUsersService";
// const serviceSubUsers = SubUsersService.build();

import Multiselect from "vue-multiselect";

export default {
  name: "ModalPermissionGroup",
  props: ["allData"],
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: true,
      name: "",
      permission: [],
      permissions: [
        // { name: "cupomMake", id: 1, title: "Criar Cupom", type:"make", description: "Sub-usuário poderá criar novos cupons de desconto" },
        // { name: "cupomRead", id: 2, title: "Visualizar Cupom", type:"view", description:"Sub-usuário terá acesso a visualizar os cupons cadastrados" },
        // { name: "cupomEdit", id: 3, title: "Editar Cupom", type:"edit", description:"Sub-usuário poderá editar todos os cupons de desconto" },
        // { name: "cupomDelete", id: 4, title: "Excluir Cupom", type:"delete", description: "Sub-usuário poderá excluir qualquer um dos cupons de desconto" },
        // { name: "ProductRead", id: 5, title: "Visualizar Produtos", type:"view", description:"Sub-usuário terá acesso a visualizar os produtos cadastrados" },
        // { name: "linkRead", id: 6, title: "Visualizar Links", type:"view", description:"Sub-usuário terá acesso a visualizar os links cadastrados" },
        // { name: "DashboardRead", id: 7, title: "Visualizar Dashboard", type:"view", description:"Sub-usuário terá acesso a visualizar todos os itens da Dashboard" },
        // { name: "SaleRead", id: 8, title: "Visualizar Vendas", type:"view", description: "Sub-usuário terá acesso a visualizar a listagem e detalhes das vendas" },
      ],
    };
  },
  methods: {
    changeCheck(data, index) {
      var permissions = this.permissions;
      if (
        data.name === "CouponEdit" ||
        data.name === "CouponDelete" ||
        data.name === "CouponCreate"
      ) {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "CouponRead" ||
              element.name === "LinkRead" ||
              element.name === "ProductRead") &&
            data.check === true
          ) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (
              (element.name === "CouponDelete" && element.check) ||
              (element.name === "CouponCreate" && element.check) ||
              (element.name === "CouponEdit" && element.check)
            ) {
            } else {
              for (let x = 0; x < permissions.length; x++) {
                const element2 = permissions[x];
                if (
                  (element.name === "CouponDelete" && element.check) ||
                  (element.name === "CouponCreate" && element.check) ||
                  (element.name === "CouponEdit" && element.check)
                ) {
                  if (
                    element2.name === "CouponRead" ||
                    element2.name === "LinkRead" ||
                    element2.name === "ProductRead"
                  ) {
                    permissions[i].check = false;
                  }
                }
              }
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "CouponRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "ProductRead" || element.name === "LinkRead") &&
            data.check === true
          ) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (element.name === "CouponRead" && element.check) {
            } else {
              console.log(element.name, element.check);
              if (
                (element.name === "CouponDelete" && element.check) ||
                (element.name === "CouponCreate" && element.check) ||
                (element.name === "CouponEdit" && element.check) ||
                element.name === "LinkRead"
              ) {
                permissions[i].check = false;
              }
              if (element.name === "SaleRead") {
                for (let x = 0; x < permissions.length; x++) {
                  const element2 = permissions[x];
                  if (element2.name === "ProductRead" && element.check) {
                    permissions[x].check = true;
                  }
                  if (element2.name === "ProductRead" && !element.check) {
                    permissions[x].check = false;
                  }
                }
              }
              for (let x = 0; x < permissions.length; x++) {
                const element2 = permissions[x];
                if (element.name === "CouponRead" && element.check) {
                  if (
                    element2.name === "ProductRead" ||
                    element2.name === "LinkRead" ||
                    element2.name === "CouponDelete" ||
                    element2.name === "CouponCreate" ||
                    element2.name === "CouponEdit"
                  ) {
                    permissions[i].check = false;
                  }
                }
              }
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "LinkRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "ProductRead" || element.name === "CouponRead") &&
            data.check === true
          ) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (
              (element.name === "CouponDelete" && element.check) ||
              (element.name === "CouponCreate" && element.check) ||
              (element.name === "CouponEdit" && element.check) ||
              element.name === "ProductRead" ||
              element.name === "CouponRead"
            ) {
              permissions[i].check = false;
            }
            for (let x = 0; x < permissions.length; x++) {
              const element2 = permissions[x];
              if (element.name === "LinkRead" && element.check) {
                if (
                  element2.name === "ProductRead" ||
                  element2.name === "CouponRead" ||
                  element2.name === "CouponDelete" ||
                  element2.name === "CouponCreate" ||
                  element2.name === "CouponEdit"
                ) {
                  permissions[i].check = false;
                }
              }
            }
          }
          if (element.name === "SaleRead") {
            let data = permissions.filter(
              (x) =>
                (x.check === true && x.name === "CouponRead") ||
                (x.check === true && x.name === "ProductRead")
            );
            permissions[i].check = false;
            if (data.length === 2) {
              permissions[i].check = true;
            } else {
              permissions[i].check = false;
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "ProductRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "SaleRead" || element.name === "CouponRead") &&
            data.check === true
          ) {
            permissions[i].check = true;
          } else if (
            (element.name === "SaleRead" ||
              element.name === "CouponRead" ||
              element.name === "LinkRead" ||
              element.name === "CouponDelete" ||
              element.name === "CouponCreate" ||
              element.name === "CouponEdit") &&
            data.check === false
          ) {
            permissions[i].check = false;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "SaleRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (element.name === "ProductRead" && data.check === true) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (element.name === "ProductRead") {
              let data = permissions.filter(
                (x) =>
                  (x.check === true && x.name === "CouponRead") ||
                  (x.check === true && x.name === "LinkRead")
              );
              permissions[i].check = false;
              if (data.length === 2) {
                permissions[i].check = true;
              } else {
                permissions[i].check = false;
              }
            }

            if (element.name === "CouponRead" && element.check) {
              permissions[i].check = true;
            } else if (element.name === "CouponRead" && !element.check) {
              permissions[i].check = false;
            }

            if (element.name === "LinkRead" && !element.check) {
              for (let x = 0; x < permissions.length; x++) {
                const element2 = permissions[x];
                if (element2.name === "CouponRead") {
                  permissions[x].check = false;
                }
              }
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
    },
    reset() {
      this.name = "";
      this.permission = [];
      this.permissions = [];
    },
    formatPermissions(name) {
      if (name === "SaleRead") {
        return "Visualizar Vendas";
      }
    },
    fetchPermissions() {
      this.loading = true;

      serviceSubUsersPermissions
        .read("")
        .then((response) => {
          this.permissions = response;

          if (this.allData.length !== 0) {
            this.name = this.allData.name;
            var permissions = this.permissions;
            var permissionsData = this.allData.sub_user_role_permission;

            for (let i = 0; i < permissionsData.length; i++) {
              const myPermissions = permissionsData[i];
              for (let x = 0; x < permissions.length; x++) {
                const element = permissions[x];
                if (myPermissions.permission_id === element.id) {
                  permissions[x].check = true;
                }
              }
            }
            this.permission = permissions;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let permissions = this.permissions;
          let permission = permissions.filter((x) => x.check === true);
          if (permission.length) {
            var id_permissions = [];
            permission.forEach((element, index) => {
              id_permissions.push(element.id);
            });

            let data = {
              name: this.name,
              permission: id_permissions,
            };

            if (this.allData.length !== 0) {
              data.id = this.allData.id;
              console.log("data -->", data);
              serviceSubGroup
                .update(data)
                .then((response) => {
                  this.resolveResponse();
                })
                .catch((err) => {
                  console.log(err);
                });

              return;
            }

            serviceSubGroup
              .create(data)
              .then((response) => {
                this.resolveResponse();
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$bvToast.toast("Selecione uma Permissão", {
              title: "Grupo de Permissões",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        }
      });
    },
    resolveResponse() {
      this.$bvToast.toast("Grupo salvo com sucesso!", {
        title: "Grupo de Permissões",
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
      this.$emit("close");
      this.reset();
      this.$root.$emit("bv::hide::modal", "modal-permission-group", "#btnShow");
    },
    openModal() {
      this.fetchPermissions();
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-list-itens {
  display: grid;
  grid-template-columns: calc(100% - 150px) 150px;
  gap: 20px;
  margin-top: 30px;
  .itens {
    gap: 20px;
    align-items: flex-start;
    border-bottom: solid 1px #ededf0;
    padding-bottom: 10px;
    p {
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      margin-bottom: 5px;
      line-height: 1;
    }
    span {
      font-weight: 400;
      font-size: 13px;
      color: #81858e;
    }
  }
}

.ml-switch {
  margin-left: 35px;
}

h3 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

p {
  font-size: 12px;
  font-weight: 300;
}
.grid-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-input {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 3px;
  .copy {
    border: none;
    color: #333;
    background: #f7f7f7;
    outline: none !important;
    font-weight: 600;
    height: auto;
    padding: 0 42px !important;
    border-radius: 5px;
    font-size: 14px;

    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
}

.code {
  background: rgb(27, 26, 29);
  color: white;
  height: auto;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  border-radius: 3px;
}
</style>
